import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/Default"
import Container from "../components/layouts/container/Container"
import parse from "html-react-parser"
import lautenschlagerLogo from "../img/webdesigner-kassel-lautenschlager-logo.png"

const Rechtliches = ({ data, location }) => {
  const text = parse(data.cockpitRechtliches.text.value)
  return (
    <Layout
      location={location}
      title={data.cockpitRechtliches.title.value}
      desc=""
    >
      <Container className="my-8 mmd:my-16">
        <div className="mx-auto text-lg max-w-prose">
          <div className="block text-base font-semibold tracking-wide text-center text-gray-700 uppercase">
            Rechtliches
          </div>
          <h1 className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
            {data.cockpitRechtliches.title.value}
          </h1>
          <div className="mt-8 text-xl leading-8 prose text-gray-500">
            {text}
          </div>
        </div>
      </Container>
      <Container>
        <div className="max-w-xl p-2 mx-auto my-16 mt-3 text-center bg-gray-100">
          <p className="mb-1 text-xs">Website erstellt von:</p>
          <a href="https://lautenschlager.de/leistungen/webdesign-kassel/" target="_blank" rel="dofollow noreferrer">
            <img
              src={lautenschlagerLogo}
              title="Webdesign Kassel - Lautenschlager Marketing & Entwicklung"
              alt="Webdesign Kassel - Lautenschlager Marketing & Entwicklung"
              className="mx-auto"
            />
            <br />
							Webdesign Kassel - Lautenschlager Marketing & Entwicklung
						</a>
        </div>
      </Container>
    </Layout>
  )
}

export default Rechtliches

export const query = graphql`
  query($id: String) {
    cockpitRechtliches(id: { eq: $id }) {
      slug {
        slug
      }
      text {
        value
      }
      title {
        value
      }
    }
  }
`
